<template>
	<div id="app">
		<ArrowOnScroll />
		<Menu />
		<router-view />
		<Footer />
	</div>
</template>

<script>
import ArrowOnScroll from "@/components/ArrowOnScroll.vue";
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";

export default {
	components: {
		ArrowOnScroll,
		Menu,
		Footer,
	},
};
</script>

<style lang="scss">
// Caractéristiques générales
// Typographies, couleurs
html {
	height: 100%;
	font-size: 62.5%;
	scroll-behavior: smooth;

	body {
		margin: 0px;
		padding: 0px;
		background-color: #111111;
		color: inherit;

		p {
			font-family: Heebo;
			color: #fbfbfb;
			font-size: calc(0vw + 0vh + 2.5vmin);
		}

		a {
			color: #fbfbfb;
			text-decoration: none;
			font-size: calc(0vw + 0vh + 2.5vmin);
		}

		a:hover {
			text-decoration: none;
			color: #3283c9;
			h3:hover {
				color: #3283c9;
			}
			p:hover {
				color: #3283c9;
			}
		}

		h2 {
			font-family: Heebo;
			font-weight: bold;
			color: #fbfbfb;
			font-size: calc(1.5vw + 1.5vh + 0vmin);
			margin-top: 1vh;
		}

		h3 {
			font-family: Heebo;
			font-weight: bold;
			color: #fbfbfb;
			font-size: calc(1vw + 1vh + 1vmin);
		}

		hr {
			border-top: 3px solid #fbfbfb;
			margin-top: 2vh;
			margin-bottom: 3vh;
		}
	}
}
</style>
