<template>
	<transition name="fade">
		<a v-if="show" @click="scrollTop" v-show="visible" class="bottom-right">
			<slot></slot>
		</a>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			visible: false,
			show: true,
		};
	},
	methods: {
		scrollTop: function() {
			window.scrollTo(0, 0);
		},
		scrollListener: function(e) {
			this.visible = window.scrollY > 150;
		},
	},
	mounted: function() {
		window.addEventListener("scroll", this.scrollListener);
	},
	beforeDestroy: function() {
		window.removeEventListener("scroll", this.scrollListener);
	},
};
</script>

<style scoped>
.bottom-right {
	position: fixed;
	bottom: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 10;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}
</style>
