<template>
	<header v-bind:style="{ 'background-image': 'url(' + infos.urlImage + ')' }">
		<h1 class="titrePage">
			{{ infos.titrePage }}
		</h1>
		<div @click="scrollOnClick()" class="flecheScroll">
			<svg class="arrows">
				<path class="a1" d="M0 0 L30 32 L60 0"></path>
				<path class="a2" d="M0 20 L30 52 L60 20"></path>
				<path class="a3" d="M0 40 L30 72 L60 40"></path>
			</svg>
		</div>
	</header>
</template>

<script>
export default {
	props: ["infos"],
	methods: {
		scrollOnClick() {
			window.scrollTo(0, window.innerHeight);
		},
	},
};
</script>

<style lang="scss">
/*Image en fond (accueil)*/

header {
	height: 100vh;
	min-height: 350px;
	background: no-repeat center center scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	// background-image: url("../assets/accueil/fond_accueil.jpg");

	.titrePage {
		margin-top: 50vh;
		transform: translateY(-50%);
		padding: 0;
		bottom: unset;
		text-align: center;
		position: absolute;
		right: 15%;
		left: 15%;

		font-family: Heebo;
		font-weight: 900;
		color: #fbfbfb;
		font-size: calc(3vw + 3vh + 0vmin);
		text-shadow: 0 0 30px #3283c9, 0 0 10px #3283c9;

		animation: titleTransition ease 1.25s;
		-webkit-animation: titleTransition ease 1.25s;
	}

	.boutonAccueil {
		font-family: "Just Another Hand";
		font-size: calc(2vw + 2vh + 1vmin);
		border: 3px solid #fbfbfb;
		padding: 7px 25px 3px 25px;
	}

	/*Flèche qui incite à scroll*/

	.flecheScroll {
		text-align: center;

		.arrows {
			width: 60px;
			height: 72px;
			position: absolute;
			left: 50%;
			margin-left: -30px;
			bottom: 20px;
			cursor: pointer;
		}

		.arrows path {
			stroke: #fbfbfb;
			fill: transparent;
			stroke-width: 2px;
			animation: arrow 2s infinite;
			-webkit-animation: arrow 2s infinite;
		}

		@keyframes arrow {
			0% {
				opacity: 0;
			}
			40% {
				opacity: 1;
			}
			80% {
				opacity: 0;
			}
			100% {
				opacity: 0;
			}
		}

		@-webkit-keyframes arrow {
			0% {
				opacity: 0;
			}
			40% {
				opacity: 1;
			}
			80% {
				opacity: 0;
			}
			100% {
				opacity: 0;
			}
		}

		.arrows path.a1 {
			animation-delay: -1s;
			-webkit-animation-delay: -1s;
		}

		.arrows path.a2 {
			animation-delay: -0.5s;
			-webkit-animation-delay: -0.5s;
		}

		.arrows path.a3 {
			animation-delay: 0s;
			-webkit-animation-delay: 0s;
		}
	}
}

@keyframes titleTransition {
	0% {
		opacity: 0;
		font-size: calc(2vw + 2vh + 0vmin);
		text-shadow: 0 0 20px #3283c9;
	}
	100% {
		opacity: 1;
		font-size: calc(3vw + 3vh + 0vmin);
		text-shadow: 0 0 30px #3283c9, 0 0 10px #3283c9;
	}
}

@-moz-keyframes titleTransition {
	0% {
		opacity: 0;
		font-size: calc(2vw + 2vh + 0vmin);
		text-shadow: 0 0 20px #3283c9;
	}
	100% {
		opacity: 1;
		font-size: calc(3vw + 3vh + 0vmin);
		text-shadow: 0 0 30px #3283c9, 0 0 10px #3283c9;
	}
}

@-webkit-keyframes titleTransition {
	0% {
		opacity: 0;
		font-size: calc(2vw + 2vh + 0vmin);
		text-shadow: 0 0 20px #3283c9;
	}
	100% {
		opacity: 1;
		font-size: calc(3vw + 3vh + 0vmin);
		text-shadow: 0 0 30px #3283c9, 0 0 10px #3283c9;
	}
}

@-o-keyframes titleTransition {
	0% {
		opacity: 0;
		font-size: calc(2vw + 2vh + 0vmin);
		text-shadow: 0 0 20px #3283c9;
	}
	100% {
		opacity: 1;
		font-size: calc(3vw + 3vh + 0vmin);
		text-shadow: 0 0 30px #3283c9, 0 0 10px #3283c9;
	}
}

@-ms-keyframes titleTransition {
	0% {
		opacity: 0;
		font-size: calc(2vw + 2vh + 0vmin);
		text-shadow: 0 0 20px #3283c9;
	}
	100% {
		opacity: 1;
		font-size: calc(3vw + 3vh + 0vmin);
		text-shadow: 0 0 30px #3283c9, 0 0 10px #3283c9;
	}
}
</style>
