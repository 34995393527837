import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/projets",
		component: () => import("../views/Projets.vue"),
	},
	{
		path: "/apropos",
		component: () => import("../views/APropos.vue"),
	},
	{
		path: "/contact",
		component: () => import("../views/Contact.vue"),
	},
	{
		path: "/vocabularylist",
		component: () => import("../views/VocabularyList.vue"),
	},
	{
		path: "/cronmanager",
		component: () => import("../views/CronManager.vue"),
	},
	{
		path: "/randomnumbertolettergenerator",
		component: () => import("../views/RandomNumberToLetterGenerator.vue"),
	},
	{
		path: "/marccorrecteur",
		component: () => import("../views/MarcCorrecteur.vue"),
	},
	{
		path: "/allinbox",
		component: () => import("../views/AllInBox.vue"),
	},
	{
		path: "/racingmaze",
		component: () => import("../views/RacingMaze.vue"),
	},
	{
		path: "/conseildepartementalhautrhin",
		component: () => import("../views/ConseilDepartementalHautRhin.vue"),
	},
	{
		path: "/openaccess",
		component: () => import("../views/OpenAccess.vue"),
	},
	{
		path: "/tokyoghoul",
		component: () => import("../views/TokyoGhoul.vue"),
	},
	{
		path: "/lard",
		component: () => import("../views/Lard.vue"),
	},
	{
		path: "/monumentsbelfortmontbeliard",
		component: () => import("../views/MonumentsBelfortMontbeliard.vue"),
	},
	{
		path: "/chronos",
		component: () => import("../views/Chronos.vue"),
	},
	{
		path: "*",
		name: "Not Found",
		component: () => import("../views/NotFound.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
