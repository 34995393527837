<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<!--Titre de la section-->
			<div class="container">
				<div class="row">
					<h2
						class="sectionMesTravaux text-center col-sm-12 col-md-12 col-lg-12 col-xl-12"
					>
						MES DERNIERS TRAVAUX
					</h2>
				</div>
			</div>

			<!--Site Vocabulary List-->
			<div class="container">
				<div class="row">
					<div class="hoverImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<router-link to="vocabularylist"
							><img
								class="img-fluid"
								src="../assets/projets/fond_vocabulary_list.jpg"
								alt="Image du site Vocabulary List"
						/></router-link>
					</div>
				</div>
				<div class="row">
					<router-link
						to="vocabularylist"
						class="titreProjetAccueil col-sm-12 col-md-12 col-lg-12 col-xl-12"
						><h3>Vocabulary List - Site web (2022)</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="vocabularylist"
						class="descriptifProjetAccueil col-sm-12 col-md-12 col-lg-12 col-xl-12"
						><p>
							Création d'un gestionnaire de vocabulaire personnel
						</p></router-link
					>
				</div>
			</div>

			<!--Projet Cron Manager-->
			<div class="container">
				<div class="row">
					<div class="hoverImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<router-link to="cronmanager"
							><img
								class="img-fluid"
								src="../assets/projets/fond_cron_manager.jpg"
								alt="Image de l'application Discord"
						/></router-link>
					</div>
				</div>
				<div class="row">
					<router-link
						to="cronmanager"
						class="titreProjetAccueil col-sm-12 col-md-12 col-lg-12 col-xl-12"
						><h3>Cron manager - BOT Discord (2022)</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="cronmanager"
						class="descriptifProjetAccueil col-sm-12 col-md-12 col-lg-12 col-xl-12"
						><p>
							Création d'un BOT Discord
						</p></router-link
					>
				</div>
			</div>

			<!--Projet Random Number to Letter Generator-->
			<div class="container">
				<div class="row">
					<div class="hoverImage col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<router-link to="randomnumbertolettergenerator"
							><img
								class="img-fluid"
								src="../assets/projets/fond_random_number_to_letter_generator.jpg"
								alt="Image du projet Random Number to Letter Generator"
						/></router-link>
					</div>
				</div>
				<div class="row">
					<router-link
						to="randomnumbertolettergenerator"
						class="titreProjetAccueil col-sm-12 col-md-12 col-lg-12 col-xl-12"
					>
						<h3>
							Random Number to Letter Generator - Site web (2022)
						</h3></router-link
					>
				</div>
				<div class="row">
					<router-link
						to="randomnumbertolettergenerator"
						class="descriptifProjetAccueil col-sm-12 col-md-12 col-lg-12 col-xl-12"
						><p>
							Création d'un générateur de nombre en lettre aléatoire
						</p></router-link
					>
				</div>
			</div>

			<!--Bouton voir tout-->
			<div class="container">
				<div class="row col-sm-12 col-md-12 col-lg-12 col-xl-12">
					<router-link to="projets" class="boutonVoirTousLesProjets"
						>VOIR TOUT</router-link
					>
				</div>
			</div>

			<hr />

			<!--Section en savoir plus-->
			<div class="container">
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
						<h2 class="titreEnSavoirPlusAccueil">
							UN PROFIL POLYVALENT POUR TOUT PROJET WEB
						</h2>
						<p>
							De par ma formation en Métiers du Multimédia et de l'Internet,
							j'ai acquis de nombreuses compétences dans les trois axes qui
							composent la formation à savoir le design, la communication et le
							développement web. <br /><br />

							J'ai pu notamment les exercer lors de nombreux projets réalisés
							durant cette formation afin de les améliorer. <br /><br />

							Grâce à cette formation, j'ai pu y découvrir une passion pour le
							développement et la programmation. Ainsi, j'ai poursuivi sur la
							Licence Professionnelle Conception-Intégration d’Applications et
							Services Web pour l’Entreprise afin de m'améliorer dans ce
							domaine.
						</p>
					</div>

					<img
						class="imageProgrammeur img-fluid col-sm-12 col-md-12 col-lg-6 col-xl-6"
						src="../assets/accueil/programmeur.svg"
						alt="Image d'un programmeur en vectoriel"
					/>
				</div>
				<!--Bouton en savoir plus-->
				<div class="row">
					<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<router-link to="apropos" class="boutonEnSavoirPlus"
							>EN SAVOIR PLUS</router-link
						>
					</div>
				</div>
			</div>

			<!--Section me contacter-->
			<div class="fondMeContacter">
				<div class="container">
					<div class="row">
						<div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
							<h2 class="titreMeContacterAccueil">
								VOUS ÊTES INTÉRESSÉ PAR CE QUE JE FAIS ?
							</h2>
							<p>
								Si vous avez un quelconque message ou des remarques à me faire
								passer, n'hésitez pas à me contacter, j'y répondrai au plus vite
								!
							</p>
						</div>
					</div>
					<!--Bouton me contacter-->
					<div class="row">
						<div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<router-link to="contact" class="boutonMeContacter"
								>ME CONTACTER</router-link
							>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
	name: "Home",
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Développeur web",
			meta: [
				{
					name: "description",
					content:
						"Portfolio d'Antonin Winterstein, développeur web. Vous trouverez ici son parcours ainsi que ses différents travaux.",
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			infos: {
				titrePage: "ANTONIN WINTERSTEIN",
				urlImage: require("../assets/accueil/fond_accueil.jpg"),
			},
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style lang="scss">
html {
	body {
		main {
			/*Travaux/Projets (accueil)*/
			.sectionMesTravaux {
				margin: 2vh 0 2vh 0;
			}

			.titreProjetAccueil {
				margin-top: 1rem;
			}

			.descriptifProjetAccueil {
				margin-bottom: 2vh;
			}

			.monumentsBelfortMontbeliard .descriptifProjetAccueil {
				margin-bottom: 0;

				p {
					margin-bottom: 0;
				}
			}

			// Animations au hover sur les images
			.hoverImage {
				overflow: hidden;
				background: #3283c9;
				padding: 0;
				margin-right: 15px;
				margin-left: 15px;
			}

			.hoverImage img {
				transition: transform 0.5s ease;
				opacity: 1;
				-webkit-transition: 0.3s ease-in-out;
				transition: 0.3s ease-in-out;
			}

			.hoverImage:hover img {
				transform: scale(1.1);
				opacity: 0.7;
			}

			/*Section en savoir plus (accueil)*/

			.titreEnSavoirPlusAccueil,
			.titreMeContacterAccueil {
				margin-bottom: 3vh;
			}

			@media screen and (max-width: 991px) {
				.videoYoutube {
					height: 400px;
				}

				.imageProgrammeur {
					display: none;
				}
			}

			/*Section me contacter (accueil)*/

			.fondMeContacter {
				background: url("../assets/accueil/do_something_great.jpg") no-repeat
					center center;
				-webkit-background-size: cover;
				-moz-background-size: cover;
				-o-background-size: cover;
				background-size: cover;
				margin-top: 1vh;

				> div {
					padding-bottom: 2vh;
					padding-top: 4vh;
				}
			}

			.boutonVoirTousLesProjets,
			.boutonVoirSite,
			.boutonEnSavoirPlus,
			.boutonMeContacter,
			.boutonEnvoyer {
				font-family: "Just Another Hand";
				padding-right: 40px;
				padding-left: 0px;
				border-style: none;
				background-image: url("../assets/fleche.svg");
				background-position: 100% 50%;
				background-size: 30px;
				background-repeat: no-repeat;
				background-color: transparent;
				-webkit-transition: padding 200ms ease;
				transition: padding 200ms ease;
				color: #3283c9;
				line-height: 2.6em;
				text-align: left;
				font-weight: 500;
				font-size: calc(0.5vw + 0.5vh + 2.5vmin);
			}

			.boutonVoirTousLesProjets:hover,
			.boutonVoirSite:hover,
			.boutonEnSavoirPlus:hover,
			.boutonMeContacter:hover,
			.boutonEnvoyer:hover {
				padding-right: 55px;
			}

			.boutonTelechargerCV {
				font-family: "Just Another Hand";
				padding-right: 35px;
				padding-left: 0px;
				padding-top: 5px;
				border-style: none;
				background-image: url("../assets/fleche_telechargement.svg");
				background-position: 100% 50%;
				background-size: 25px;
				background-repeat: no-repeat;
				background-color: transparent;
				-webkit-transition: padding 200ms ease;
				transition: padding 200ms ease;
				color: #3283c9;
				line-height: 2.6em;
				text-align: left;
				font-weight: 500;
				font-size: calc(1vw + 1vh + 2.5vmin);
			}
		}
	}
}
</style>
