<template>
	<!-- Logo + barres du menu hamburger qu'on retrouve dans toutes les pages -->
	<nav>
		<div class="container-fluid position-absolute">
			<div class="navbar">
				<router-link to="/" class="navbar-brand">
					<svg
						class="logo"
						xmlns="http://www.w3.org/2000/svg"
						width="43"
						height="237.531"
						viewBox="0 0 63 237.531"
					>
						<g id="Logo" transform="translate(-118.795 -260.272)">
							<text
								id="A"
								transform="translate(169.767 305.272) rotate(180)"
								fill="#3283c9"
								font-size="121"
								font-family="JustAnotherHand-Regular, Just Another Hand"
							>
								<tspan x="0" y="0">A</tspan>
							</text>
							<text
								id="W"
								transform="translate(118.795 452.803)"
								fill="#3283c9"
								font-size="123"
								font-family="JustAnotherHand-Regular, Just Another Hand"
							>
								<tspan x="0" y="0">W</tspan>
							</text>
						</g>
					</svg>
				</router-link>
				<a class="nav-button"
					><span id="nav-icon3"
						><span></span><span></span><span></span><span></span></span
				></a>
			</div>
		</div>

		<!--Contenu du menu hamburger-->
		<div class="fixed-top main-menu menu">
			<div class="flex-center">
				<ul class="nav flex-column col-sm-10 col-md-10 col-lg-10 col-xl-10">
					<li class="nav-item delay-1">
						<router-link to="/" class="nav-link">ACCUEIL</router-link>
					</li>
					<li class="nav-item delay-2">
						<router-link to="projets" class="nav-link">PROJETS</router-link>
					</li>
					<li class="nav-item delay-3">
						<router-link to="apropos" class="nav-link">À PROPOS</router-link>
					</li>
					<li class="nav-item delay-4">
						<router-link to="contact" class="nav-link">CONTACT</router-link>
					</li>
				</ul>
				<img
					class="chouette"
					src="../assets/menu/chouette.svg"
					alt="Dessin vectoriel d'une chouette blanche"
				/>
			</div>
		</div>
	</nav>
</template>

<script>
// Menu
export default {
	mounted() {
		$(".nav-button").click(function() {
			$("nav").toggleClass("nav-open");
			$("body").toggleClass("overflow");
		});

		$(".navbar-brand, .nav-link").click(function() {
			$("nav").removeClass("nav-open");
			$("body").removeClass("overflow");
		});
	},
};
</script>

<style lang="scss">
// Menu
// Typographies, couleurs

// Menu
// Mise en page
.overflow {
	overflow: hidden;
}

.nav-open {
	.main-menu {
		opacity: 1;
		visibility: visible;
		background: #111111;
	}
}

.main-menu {
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;

	li a {
		font-family: Heebo;
		font-weight: 900;
		color: #fbfbfb;
		font-size: calc(4vw + 4vh + 0vmin);
	}
}

.nav-button {
	position: relative;
	z-index: 1111;
	height: 42px;
	display: block;
	width: 50px;
	padding-top: 45px;
	right: -18px;
}

.nav-button #nav-icon3 {
	width: 32px;
	height: 22px;
	display: inline-block;
	position: fixed;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.5s ease-in-out;
	-moz-transition: 0.5s ease-in-out;
	-o-transition: 0.5s ease-in-out;
	transition: 0.5s ease-in-out;
	cursor: pointer;
}

.nav-button #nav-icon3 span {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	background: #3283c9;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: 0.25s ease-in-out;
	-moz-transition: 0.25s ease-in-out;
	-o-transition: 0.25s ease-in-out;
	transition: 0.25s ease-in-out;
}

.nav-button #nav-icon3 span:nth-child(1) {
	top: 0;
}

.nav-button #nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
	top: 10px;
}

.nav-button #nav-icon3 span:nth-child(4) {
	top: 20px;
}

.nav-open #nav-icon3 span:nth-child(1) {
	top: 9px;
	width: 0;
	left: 50%;
}

.nav-open #nav-icon3 span:nth-child(2) {
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.nav-open #nav-icon3 span:nth-child(3) {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.nav-open #nav-icon3 span:nth-child(4) {
	top: 9px;
	width: 0;
	left: 50%;
}

.flex-center {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 100%;
}

.flex-center > ul {
	padding: 3rem;
}

.logo {
	position: fixed;
	z-index: 1111;
	top: -30px;
}

.chouette {
	right: 0;
	width: 12%;
	height: auto;
	position: absolute;
}

@media screen and (max-width: 1200px) {
	.chouette {
		width: 20%;
	}
}

@media screen and (max-width: 479px) {
	.chouette {
		width: 18%;
	}
}

// Menu
// Animations
.main-menu .nav li {
	opacity: 0;
	-webkit-transform: translateY(-40px);
	transform: translateY(-40px);
	-webkit-animation-timing-function: cubic-bezier(0.45, 0.005, 0, 1);
	-moz-animation-timing-function: cubic-bezier(0.45, 0.005, 0, 1);
	-o-animation-timing-function: cubic-bezier(0.45, 0.005, 0, 1);
	animation-timing-function: cubic-bezier(0.45, 0.005, 0, 1);
}

.nav-open .main-menu .nav li {
	opacity: 1;
	-webkit-transform: translateY(0px);
	transform: translateY(0px);
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
	transition: transform 0.2s, opacity 0.2s;
}

.main-menu .nav li.delay-1 {
	-webkit-transition-delay: 0.1s;
	-moz-transition-delay: 0.1s;
	-ms-transition-delay: 0.1s;
	-o-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.main-menu .nav li.delay-2 {
	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	-ms-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.main-menu .nav li.delay-3 {
	-webkit-transition-delay: 0.3s;
	-moz-transition-delay: 0.3s;
	-ms-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.main-menu .nav li.delay-4 {
	-webkit-transition-delay: 0.4s;
	-moz-transition-delay: 0.4s;
	-ms-transition-delay: 0.4s;
	-o-transition-delay: 0.4s;
	transition-delay: 0.4s;
}
</style>
