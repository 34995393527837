<template>
	<!-- <a class="flecheHautDePage" id="button" href=""
		><img src="../assets/fleche_remonter.svg" alt="Flèche pour remonter"
	/></a> -->
	<ScrollTopComponent>
		<a>
			<img
				src="../assets/fleche_remonter.svg"
				alt="Flèche pour remonter"
				width="75%"
			/>
		</a>
	</ScrollTopComponent>
</template>

<script>
import ScrollTopComponent from "./ScrollTopComponent";
export default {
	components: {
		ScrollTopComponent,
	},
};
</script>
